import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const LinkToJiraIssue = () => {
  return (
    <LayoutComponent>
      <h3>Create or Link to a Jira Issue</h3>
      <p>
        <p>
          You can create a new defect or link an existing one to a test cycle,
          execution or to a test case/ step.
        </p>
        <p>Use one of the following two options in the Issues section:</p>
        <ul>
          <li>Create a new Issue: A popup will appear. Fill out your desired fields on the Create Issue screen, then click Create to link the new issue.</li>
          <li>Link an existing one issue: A popup will appear. Select the issues you want to link.</li>
        </ul>
        <p></p>
      </p>
    </LayoutComponent>
  )
}

export default LinkToJiraIssue
